import React, { useEffect, useState } from "react";
import "./App.css";
import theme from "./style/theme";
import { ThemeProvider } from "@mui/material/styles";
import HeaderBasic from "./HeaderBasic";
import HomePage from "./home/HomePage";
import AboutPage from "./about/AboutPage";
import PosterPage from "./poster/PosterPage";
import CurrentLink from "./current-link/CurrentLink";

export const PAGES = {
  home: "voting",
  about: "about",
  poster: "poster",
};

function App() {
  const [currentPage, setCurrentPage] = useState(PAGES.home);

  const navigate = (url: string) => {
    window.location.href = url;
  };

  useEffect(() => {
    const match = Object.values(PAGES).find((page) =>
      window.location.href.includes(page)
    );
    if (match) {
      setCurrentPage(match);
    }
  }, []);

  useEffect(() => {
    if (
      !Object.values(PAGES).find((page) => window.location.href.includes(page))
    ) {
      setCurrentPage("current-link");
    }
  }, []);

  return (
    <div className="App" style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <HeaderBasic onInfoOpen={() => {}} navigate={navigate} />
        <PosterPage navigate={navigate} />
        {/* {currentPage === PAGES.home && <HomePage navigate={navigate} />}
        {currentPage === PAGES.about && <AboutPage navigate={navigate} />}
        {currentPage === PAGES.poster && <PosterPage navigate={navigate} />}
        {currentPage === "current-link" && <CurrentLink />} */}
      </ThemeProvider>
    </div>
  );
}

export default App;
