import * as React from "react";
import { PAGES } from "./App";

function HeaderBasic({
  navigate,
  onInfoOpen,
}: {
  navigate: (x: string) => void;
  onInfoOpen: () => void;
}) {
  return (
    <div
      style={{ height: 30, marginTop: 15, fontSize: 22, cursor: "pointer" }}
      onClick={() => navigate(PAGES.home)}
    >
      THE INKED LINK
    </div>
  );
}

export default HeaderBasic;
